import { Box, Modal, Typography } from "@mui/material";

import AuctionRealTimeTable from "../Tables/AuctionRealTimeTable";
import MainLoader from "../Loaders/MainLoader";
import { useRealTimeAuctions } from "../../hooks";

type Props = {
  open: boolean;
  handleClose: () => void;
  id: number;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  backgroundColor: "#FFFF",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  borderRadius: "10px",
  padding: "2rem 2.5rem",
  width: "1250px",
  display: "flex",
  flexDirection: "column",
  maxHeight: "90vh",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
  },
};

const AuctionRealTimeModal = ({ open, handleClose, id }: Props) => {
  const { auctions } = useRealTimeAuctions(String(id));
  console.log(auctions);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-Address"
        aria-describedby="modal-modal-add-address"
      >
        <Box sx={style}>
          <>
            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "700",
                color: "#34B44A",
                textAlign: "center",
                marginBottom: ".25rem",
                textDecoration: "underline",
              }}
            >
              تفاصيل المزاد
            </Typography>
            <AuctionRealTimeTable rows={auctions} productId={id} />
          </>
        </Box>
      </Modal>
    </>
  );
};

export default AuctionRealTimeModal;
