import { Box, Modal, Typography } from "@mui/material";
import React, { useMemo } from "react";

import { AUCTION_NEW_STATUS } from "../../constants/auction.constants";
import AuctionsDetailsTable from "../Tables/AuctionsDetailsTable";
import MainLoader from "../Loaders/MainLoader";
import useGetSingleApi from "../../hooks/useGetSingleApi";

type Props = {
  open: boolean;
  handleClose: () => void;
  id: number | null;
  status?: "new" | undefined;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  backgroundColor: "#FFFF",
  border: "1px solid rgba(0, 0, 0, 0.08)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.09)",
  borderRadius: "10px",
  padding: "2rem 2.5rem",
  width: "1250px",
  display: "flex",
  flexDirection: "column",
  maxHeight: "90vh",
  overflowY: "auto",
};

const AuctionsDetailsModal = ({ open, handleClose, id, status }: Props) => {
  const apiUrl = useMemo(
    () =>
      `auction/get_product_auctions?product_id=${id || ""}&status_id=${
        status === "new" ? AUCTION_NEW_STATUS : ""
      }`,
    [id, status]
  );
  const { data, loading, error } = useGetSingleApi(apiUrl, []);
  if (!id) return <></>;
  if (error) return <></>;

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-Address"
        aria-describedby="modal-modal-add-address"
      >
        <Box sx={style}>
          {loading ? (
            <MainLoader />
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "700",
                  color: "#34B44A",
                  textAlign: "center",
                  marginBottom: ".25rem",
                  textDecoration: "underline",
                }}
              >
                تفاصيل المزاد
              </Typography>
              <AuctionsDetailsTable rows={data?.data?.data} productId={id} />
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default AuctionsDetailsModal;
